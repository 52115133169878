#aboutus-top-img .top-banner img {
    height: 100vh;
    /* margin-top: -9rem; */
}

.overlay-theme {
    background-color: black;
    height: 100vh;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0.5;
}

.overlay-text {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;

}

.overlay-text h1 {
    color: white;
    font-weight: 400;

}

.section-spacing {
    padding: 2rem 0rem;
}

#About-us-hero .about-text {
    text-align: center;
}

#About-us-hero .Section-title {
    margin-bottom: 2rem;
    font-weight: 600;
    color: #000000;
    letter-spacing: 3px;
}

#About-us-hero .Section-title span {
    margin-bottom: 2rem;
    font-weight: 600;
    color: #204A44;
    font-size: 40px;
}

p {
    color: rgb(63, 63, 63);
}

/* #About-us-hero .about-us-card{
    background-image: url("https://images.unsplash.com/photo-1551554781-c46200ea959d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    height: 80vh;
    height: 100%;
     position: absolute;

}*/
.overlay-theme-2 {
    position: absolute;
    background-color: black;
    height: 80vh;
    opacity: 0.5;

}

/* .about-us-cards{
    position: absolute;
    top: 50%;
} */
/* /* .about-us-card{
    position: relative;
} */
/* .about-us-cards{
    position: absolute;
    display: flex;
    align-items: center;
    top: 0%;
    top: 50%;
}  */
/* .card-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
} */

.about-us-card {
    /* background-color: rgb(234, 234, 234); */
    background-attachment: fixed;
    background-image: url("https://images.unsplash.com/photo-1550053808-52a75a05955d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");

}


.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.custom-card:hover .card-title {
    color: #204A44;
}

.custom-card {
    transition: transform 0.3s;
}

.custom-card:hover {
    transform: scale(1.05);
    transition: transform 0.3s;
}


.swiper-slide {
    background-color: #fff;
    /* Set background color of slides */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    /* Add shadow effect */
    /* border-radius: 10px;  */
}

.author-img {
    display: flex;
    justify-content: end;
}

/* Responsive styles */
@media (max-width: 768px) {
    .mySwiper {
        width: 100%;
    }

    .author-img {
        display: flex;
        justify-content: center;
    }
}
.author-text{
    display: flex;
    align-items: center;
    height: 100%;
}
.author-text h2 {
    font-weight: 400;
    font-size: 40px;

}

.author-text h2 span {
    font-weight: 600;
    font-size: 40px;
}

.author-text hr {

    width: 30px;
    border: 2px solid green;
    opacity: 0.3;
}

.author-info img {
    border-radius: 50%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}
@import url('https://fonts.googleapis.com/css2?family=Alice&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alice&family=Lora:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

p, .img-title, .image-title{
    font-family: "Alice", serif;
}
.card-title, .footerSocialLinks, .copyright-text, .nav-link {
    font-family: "Lora", serif;
}
.Section-title {
    font-family: "Playfair Display", serif;
}
h1, h2, h5 {
    font-family: "Playfair Display", serif;
}
.cardDescription{
    font-family: "Alice", serif;
}
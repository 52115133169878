.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Adjust the opacity by changing the last value (0.5) */
    pointer-events: none;
    /* Allows clicking through the overlay */
}


.roomImage {
    width: 100%;
    border-radius: 2px;
}

.roomStonesUsedImg {
    width: 100%;
    border-radius: 2px;
}

.titleunderline {
    width: 10%;
    height: 3px;
    margin-left: 12px;
    background-color: rgb(9, 63, 9);
    margin-bottom: 20px;
}

.homeLinks {
    text-decoration: none;
    color: rgb(9, 63, 9);
}

.home-btn-set {
    display: flex;
    /* justify-content: space-around; */
    margin: 10px 0px;
}

.homeButton1 {
    text-decoration: none;
    color: rgb(9, 63, 9);
    /* background-color: #D1970D; */
    border: 1px solid rgb(9, 63, 9);
    padding: 10px 20px;
    width: fit-content;
    margin-right: 15px;
    transition: .5s;

}

.homeButton1:hover {
    color: #000;
    background-color: #D1970D;
    transition: .5s;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.homeButton2 {
    text-decoration: none;
    color: #D1970D;
    /* background-color: #D1970D; */
    border: 1px solid #D1970D;
    padding: 10px 20px;
    width: fit-content;
    transition: .5s;

}

.homeButton2:hover {
    color: #FFF;
    background-color: rgb(9, 63, 9);
    transition: .5s;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.mobile {
    display: none;
}

.laptop {
    display: block;
}

.homeimg_mobile {
    display: none;
}

.homeimg_laptop {
    display: block;
}

.homeimg_mobile img {
    width: 100%;
    height: auto;
}

.homeimg_laptop img {
    width: 100%;
    height: auto;
}

@media (max-width: 460px) {
    .homeimg_mobile {
        height: 100%;
        display: block;
    }

    .homeimg_laptop {
        display: none;
    }
}

@media (max-width: 767px) {
    .mobile {
        display: block;
    }

    .laptop {
        display: none;
    }
}
.slite-spacing{
    padding: 1rem 0rem;
}
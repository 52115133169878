* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

body {
  height: 100%;
  width: 100%;
  background-color: white;
}

.navbar-toggler {
  color: transparent;
  
}

.navbar-toggle-icon {
  color: red;
}
.navbar-toggler:focus,
.navbar-toggler:active {
  border: 1px solid rgb(255, 255, 255);
}

.navbar-toggler-icon:focus,
.navbar-toggler-icon:active {
  outline: none;
  border: none;
  box-shadow: none;
  
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  margin: 2rem 0rem;
  /* border-radius: 4px; */
  /* background-color: rgb(220, 220, 220); */
  padding: 0px 20px;
  transition: .5s;
  z-index: 500;
}

.navbar-brand img {
  width: 3.5rem;
  background: white;
  padding: 10px;
  border-radius: 55%;
}

.sticky {
  margin: 0;
  transition: .5s;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0.5rem 0rem;

}

.navbar-nav .nav-link {
  color: white;
}

.navbar-nav .nav-link.active {
  color: white;
}

.sticky .navbar-nav .nav-link {
  color: rgb(0, 0, 0);
}

.navbar-brand {
  color: white;
}

.sticky .navbar-brand {
  color: green;
}

.navbar .navbar-nav {
  width: 100%;
  display: flex;
  justify-content: end;
}

@media (max-width:600px) {
  .navbar {
    position: fixed;
    top: 0;
    width: 100%;

    /* border-radius: 4px; */
    /* background-color: rgb(220, 220, 220); */
    padding: 10px 8px;
    transition: .5s;
    z-index: 500;
  }

  .navbar .navbar-nav {
    width: 100%;
    display: flex;
    justify-content: end;
  }
}

@media (max-width:992px) {
  .navbar .navbar-nav {
    width: 100%;
    display: flex;
    justify-content: end;
    background-color: white;
    padding: 0px 30px;
  }

  .navbar-nav .nav-link {
    color: rgb(0, 0, 0);
  }

  .navbar-nav .nav-link.active {
    color: rgb(0, 0, 0);
  }

  /* .sticky .navbar-nav .nav-link {
    color: rgb(255, 255, 255);
  }
  
  .sticky .navbar-nav .nav-link.active {
    color: rgb(255, 255, 255);
  } */
  .sticky .navbar-nav {
    width: 100%;
    display: flex;
    justify-content: end;
    background-color: transparent;
  }
}
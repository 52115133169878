.collection-tabs {
    display: flex;
    /* justify-content: center; */
    width: 100%;
}

.tabs {
    width: 100%;
}

.ant-tabs-content {
    text-align: center;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
    border: none;
}

.tab-content-img .marbles-images {
    width: 100%;
    padding: 5px;
    height: 50vh;
}

@media (max-width:600px) {
    .tab-content-img .marbles-images {
        width: 100%;
        padding: 5px;
        height: 100%;
    }
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-tabs .ant-tabs-tab:hover {
    color: rgb(32, 74, 68);
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: rgb(32, 74, 68);
    text-shadow: 0 0 0.25px currentcolor;

}


.ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 1px solid rgb(32, 74, 68);
    z-index: 2;

}

#image-text {
    position: relative;
    background-color: rgb(216, 216, 216);
    margin: 10px 10px;
}

#image-text .image-title {
    font-size: 20px;
    color: rgb(32, 74, 68);



}

/* #image-text:hover .overlay-text{
    display: block;
} */
.collection-tab-heading {
    color: #204A44;
}
#Footer {
    background-color: rgb(243, 243, 243);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0rem;
}

@media (max-width:600px){
    #Footer {
        background-color: rgb(243, 243, 243);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-top: 2rem;
        padding-bottom: 0rem;
    }
    
    
}
.forms-fields {
    width: 70%;
}


#main-form-container div {
    display: flex;
    justify-content: center;
    margin: 20px 0px;
}

#main-form-container div textarea {
    border: none;
    outline: none;
    margin: 0px 10px;
    border-radius: 5px;
}

#main-form-container div input {
    border: none;
    outline: none;
    margin: 0px 10px;
    border-radius: 5px;
    height: 30px;

}

.forms-fields {
    width: 100%;
    padding: 0px 4rem;
}

.input-group {
    position: relative;
    display: flex;
    /* flex-wrap: wrap; */
    /* align-items: stretch; */
    width: 100%;
    margin: 8px 0px;
    border-radius: 5px;
    background-color: white;
}

.input-group i {
    font-size: 20px;
    padding: 5px 10px;
    background-color: rgb(236, 236, 236);
    border-radius: 5px;
}

.input-group input {
    border: none;
    outline: none;
    width: 90%;
    padding: 0px 20px;
}
@media (max-width:700px){
    .forms-fields {
        width: 100%;
        padding: 0px 0rem;
    }
    .input-group input {
        border: none;
        outline: none;
        width: 70%;
        padding: 0px 20px;
    }
}

.input-group textarea {
    width: 90%;
    padding: 0px 20px;
}
.submit{
    border: none;
    padding: 6px 60px;
    color: white;
    background-color: #204A44;
    border-radius: 5px;
    position: relative;
}
.submit i{
    /* display: none; */
    position: absolute;
    left: 30px;
    transition: .5s;
    transform: translatex(0px);

}
.submit:hover i{
    display: block;
    transition: 0.5s;
    transform: translatex(5px);
}

.social-icons .both{
    /* display: flex; */
    justify-content: space-between;
    text-align: start;
    padding: 0rem 3rem;
    margin: 30px 0px;

}
.social-icons .both span{
    color: #204A44;
    font-weight: 600;
}
.social-icons .both i{
    font-size: 20px;
    margin: 0px 10px;
    color: white;
    background-color: #573c06;
    padding: 5px 8px;
    border-radius: 50%;
}
@media(max-width:900px){
    .social-icons .both{
        /* display: flex; */
        justify-content: space-between;
        text-align: start;
        padding: 0rem 0rem;
        margin: 30px 0px;
    
    }
    .upper-section{
        padding-top: 1rem;
    }
}
.copyright-text{
    color: white;


}
.copyright-text a{
    color: white;
    text-decoration: none;
    font-weight: 600;
}
.both a{
    text-decoration: none;
}
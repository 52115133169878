/* #virtual-room-container .top-img img{
    width: 100%;
    background-color: red;
} */
#virtual-room-container .bottom-img img {
    width: 100%;
    /* background-color: rgb(0, 0, 0); */
}

#virtual-room-container .mySwiper {
    height: 30vh;
}

#virtual-view-container {
    /* margin: 0rem 4rem; */
    /* background-color: red; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.slite-space {
    padding: 1rem 0rem;

}

/* .swiper-slide-view {
    margin: 0rem 4rem;

} */

@media (max-width:600px) {
    .swiper-slide-view {
        margin: 0rem 0rem;

    }

    #virtual-view-container {
        margin: 0rem 0rem;
        /* background-color: red; */
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
}

.swiper-button-next:after {
    background-color: rgb(0, 0, 0);
    color: white;
    font-size: 8px;
    padding: 8px 10px;
    /* border-radius: 50%; */
    /* position: absolute; */
    /* bottom: -30px; */
}

.swiper-button-prev:after {
    background-color: rgb(0, 0, 0);
    color: white;
    font-size: 8px;
    padding: 8px 10px;
    /* border-radius: 50%; */
    /* position: absolute; */
    /* bottom: -30px; */
}

.img-title {
    width: 100%;
    color: black;
    padding: 4px 0px;
    box-sizing: border-box;
    text-align: center;
    font-weight: 600;
}

.slide-boxex-heading {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    color: #204A44;
}

.tab-title {
    font-size: 25px;
    color: #204A44;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
    justify-content: center;
}

:where(.css-1rqnfsa).ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
:where(.css-1rqnfsa).ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
    justify-content: center;
}

@media (max-width:600px) {
    .ant-tabs-nav-list {
        transform: translate(0px, 0px);
        flex-direction: column;
        text-align: start;
        width: 100%;
    }

    :where(.css-dev-only-do-not-override-1rqnfsa).ant-tabs .ant-tabs-tab+.ant-tabs-tab {
        margin: 0px;
    }
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-tabs .ant-tabs-ink-bar {
    display: none;
}

.ant-tabs-tab.ant-tabs-tab-active {
    background-color: rgb(237, 237, 237);
    padding: 0px 3px;
    border-radius: 2px;
}

.section-title {
    margin-bottom: 2rem;
    font-weight: 600;
    color: #000000;
    letter-spacing: 3px;
    text-align: center;
}

.section-title span {
    margin-bottom: 2rem;
    font-weight: 600;
    color: #204A44;
    font-size: 40px;
}

.top-content p {
    font-size: 1.2rem;
    background-color: rgb(238, 238, 238);
    padding: 5px;
    font-weight: 600;
}

/* :where(.css-1rqnfsa).ant-tabs .ant-tabs-tab+.ant-tabs-tab{
    margin: 0px;
} */
@media (max-width:600px) {
    :where(.css-1rqnfsa).ant-tabs .ant-tabs-tab+.ant-tabs-tab {
        margin: 0px;
    }
}

:where(.css-1rqnfsa).ant-tabs .ant-tabs-ink-bar {
    display: none;
}


/* Styles for virtual room slider scrollbar */
.mySwiper::-webkit-scrollbar {
    width: 8px;
    /* width of the scrollbar */
    height: 5px;
    /* margin-top: 30px; */
}

.mySwiper::-webkit-scrollbar-thumb {
    background-color: #204A44;
    /* color of the scrollbar thumb */
    border-radius: 4px;
    /* roundness of the scrollbar thumb */
}

.mySwiper::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* color of the scrollbar track */
}